import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Expense } from '../expense';
import { ReadFile, FilePickerDirective, ReadMode } from 'ngx-file-helpers';
import { of } from 'rxjs';
import { RedwoodService } from '../../redwood.service';

@Component({
  selector: 'expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent {

  public readMode = ReadMode.ArrayBuffer;
  public picked: ReadFile;

  public filePickerDescription;
  public limitMB = 10;

  constructor(private redwoodService: RedwoodService) {
    this.filePickerDescription = 'Velg vedlegg (inntil ' + this.limitMB + 'MB)';
  }

  @ViewChild(FilePickerDirective)
  private filePicker;

  @Input() expense: Expense;
  @Output() remove: EventEmitter<Expense> = new EventEmitter<Expense>();
  @Output() extensionInvalid: EventEmitter<string> = new EventEmitter<string>();

  delete() {
    this.remove.emit(this.expense);
  }

  attach(file: ReadFile) {
    if (file) {
      if (this.hasValidFileExtension(file.name)) {
        var fileLimit = 1024 * 1000 * this.limitMB;
        if (file.underlyingFile.size > fileLimit) {
          this.extensionInvalid.emit('Vedlegget er for stort, det må være mindre enn ' + this.limitMB + 'MB.');
        } else {
          this.picked = file;
          this.expense.attachment = file.name;
          this.expense.uploaded = () => {
            return this.redwoodService.upload(this.picked.name, this.picked.underlyingFile);
          }
        }
      } else {
        this.extensionInvalid.emit('Ugyldig filtype "' + this.getFileExtension(file.name) + '". (Må være pdf eller jpeg.)');
      }
    }
  }

  private hasValidFileExtension(fileName: string) {
    const extension = this.getFileExtension(fileName);
    return ['pdf', 'jpeg', 'jpg'].indexOf(extension) !== -1;
  }

  private getFileExtension(fileName: string) {
    const split = fileName.split('.');
    const extension = split[split.length - 1].toLowerCase();
    return extension;
  }

  unattach() {
    this.picked = undefined;
    this.expense.attachment = undefined;
    this.expense.uploaded = () => of(undefined);
  }
}
