import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountComponent } from './account.component';
import { Client } from '../httpclient';

@Component({
    template: `
    <h1 mat-dialog-title>Velg klient</h1>
    <div mat-dialog-content>
        <div *ngFor="let client of clients">
            <button mat-raised-button (click)="select(client)" color="accent" fxFlex="100%">
                {{client.name}}
            </button>
        </div>
    </div>
    `
})
export class ClientsDialog {

    constructor(
        public dialogRef: MatDialogRef<AccountComponent>,
        @Inject(MAT_DIALOG_DATA) public clients: Client[]) {
        if (clients.length < 2) {
           this.dialogRef.close(clients[0]);
        }
    }

    public select(client: Client) {
        this.dialogRef.close(client);
    }
}
