<mat-toolbar color="primary">
  <span>
    Min side
  </span>
</mat-toolbar>
<main *ngIf="forms">

  <div fxLayout='row' fxLayout.sm='column' fxLayout.xs='column'>

    <mat-card fxFlex="25%" class="menu">
      <mat-card-header>
        <mat-card-title>Du er logget inn som</mat-card-title>
        <mat-card-title>{{account.name}}</mat-card-title>
      </mat-card-header>      
      <mat-card-actions>
        <div fxFlex></div>
        <button mat-button color="primary" (click)="logout()">LOGG UT</button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="preview" fxFlex="75%" fxFlex.sm="100%">
      <mat-card-header>        
        <mat-card-title>
          {{preview.form.purpouse}}
        </mat-card-title>
        <mat-card-subtitle>
          Fakturanummer: {{preview.submitId}} Totalbeløp: {{preview.form | claimTotal | currency:'NOK':'code':'1.2-2' }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <mat-card class="confirmed-sent">
          <mat-card-content>
            Skjemaet ble sendt inn {{ preview.date | date:'short' }}
            <div fxFlex></div>
            <mat-icon svgIcon="thumbs-up"></mat-icon>
          </mat-card-content>
        </mat-card>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Utbetales av:
            </mat-panel-title>
            <mat-panel-description>
              {{preview.form.client.name}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            <tr>{{ preview.form.client.address}}</tr>
            <tr>{{ preview.form.client.zip }} {{ preview.form.client.city }}</tr>
            <tr>
              <td>Org.nr:</td>
              <td>{{ preview.form.client.orgNr }}</td>
            </tr>
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Utbetales til:
            </mat-panel-title>
            <mat-panel-description>
              {{preview.form.name}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            <tr>{{ preview.form.address}}</tr>
            <tr>{{ preview.form.zip }} {{ preview.form.city }}</tr>
          </p>
          <p>
            <tr>
              <td>Bankkontor:</td>
              <td>{{ preview.form.bankaccount }}</td>
            </tr>
            <tr>
              <td>Fødselsdag:</td>
              <td>{{ preview.form.birthdate | date:'shortDate' }}</td>
            </tr>
            <tr>
              <td>Avdeling:</td>
              <td>{{ preview.form.department }}</td>
            </tr>
          </p>
        </mat-expansion-panel>

        <div *ngIf='preview.form.expenses.length > 0' class="expenses">
          <h5>Utlegg</h5>

          <table class="table">
            <thead>
              <tr>
                <th>Nr</th>
                <th>Beskrivelse (lenke åpnes i ny fane)</th>
                <th>Beløp</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let expense of preview.form.expenses' (click)="onSelect(expense)">
                <td>{{ expense.no }}</td>
                <td><a href="{{expense.url}}" target="_new">{{ expense.name }}</a></td>
                <td>{{ expense.amount | currency:'NOK':'code':'1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </mat-card-content>
      <mat-card-actions>
        <div fxFlex></div>
        <button mat-button color="primary" (click)="onBack()">VIS ALLE UTLEGG</button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="!preview" fxFlex="75%" class="main">
      <mat-card-header>
      <mat-card-title>
        {{pageTitle}}        
      </mat-card-title>
      <button mat-raised-button color="accent" (click)="createExpense()" class="create" [disabled]="clients.length === 0">
        Nytt utlegg
      </button>
      <mat-card-subtitle *ngIf='forms.length === 0'>
        Du har ingen utlegg.
      </mat-card-subtitle>
    </mat-card-header>
      <mat-card-content *ngIf='forms.length > 0'>
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th fxShow.xs="false">Dato</th>
              <th>Beskrivelse</th>
              <th fxShow.xs="false">Antall utlegg</th>
              <th>Beløp</th>
              <th>Detaljer</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let form of forms'>
              <td>{{ form.id }}</td>
              <td fxShow.xs="false">{{ form.form.date | date:'shortDate' }}</td>
              <td>{{ form.form.purpouse }}</td>
              <td fxShow.xs="false">{{ form.form.expenses.length }}</td>
              <td>{{ form.form | claimTotal | currency:'NOK':'code':'1.2-2' }}</td>
              <td><button mat-raised-button color="neutral" (click)="onSelect(form)">Vis</button></td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</main>