import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(
    private router: Router) {
  }

  public redirect(mobile: boolean){
    let signicatUrl = mobile? environment.signicatUrlMobile : environment.signicatUrlBasic;
    let url = signicatUrl + encodeURI('account');
    this.router.navigate(['/externalRedirect', { externalUrl: url }]);
  }
}


