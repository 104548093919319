import { NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { Directive } from '@angular/core';

export function accountNumberValidator(control: UntypedFormControl) {
  let bankAccount = control.value;
  if (bankAccount) {
    if (bankAccount.length == 11) {
      if (validateAccountNumber(bankAccount)) {
        return null;
      }
    }
    return {
      bankAccount: {
        parsedBankAccount: 'INVALID'
      }
    };
  }
  return null;
}

function validateAccountNumber(accountNumber: string) {
  let ac = accountNumber.split('.').join('');
  return parseInt(ac.charAt(ac.length - 1), 10) === mod11OfNumberWithControlDigit(ac);
}

function mod11OfNumberWithControlDigit(val: string) {
  let controlNumber = 2;
  let sumForMod = 0;
  let i = 0;
  for (i = val.length - 2; i >= 0; --i) {
    sumForMod += parseInt(val.charAt(i)) * controlNumber;
    if (++controlNumber > 7) controlNumber = 2;
  }
  var result = (11 - sumForMod % 11);
  return result === 11 ? 0 : result;
}

@Directive({
  selector: '[bankAccount][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: accountNumberValidator,
      multi: true
    }
  ]
})
export class BankAccountValidator {
}