import { FormComponent } from './form/form.component';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class UnsavedGuard implements CanDeactivate<FormComponent> {
  canDeactivate(component: FormComponent): boolean { 
    if(component.hasUnsavedData()){
        if (confirm("Du har ulagrede endringer! Hvis du forlater skjemaet, vil dine endringer gå tapt.")) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}