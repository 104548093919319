<mat-card class="receipt">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>
      <img src="assets/toplogo.png" alt="Knif Regnskap AS" />
    </mat-card-title>    
  </mat-card-header>
  <img mat-card-image src="assets/achievement.jpeg" alt="Innsendingen var vellykket" />
  <mat-card-content>
    <p>
      Takk! Dette er en bekreftelse på at vi har motatt ditt skjema. 
    </p>
  </mat-card-content>
  <mat-card-actions fxLayout='row'>
    <a fxFlex="50" mat-raised-button color="primary" appExternalUrl href="http://knifregnskap.no">Gå til hjemmesiden</a>    
    <a fxFlex="50" mat-raised-button color="primary" appExternalUrl href="https://knifregnskap.net">Gå til kundeportalen</a>    
  </mat-card-actions>
</mat-card>