<mat-card class="expense">
  <mat-card-header>
    <mat-card-title>
      Utlegg
      <button mat-raised-button (click)="delete()" color="warn" class="cornered">
        Fjern utlegg
      </button>
      <button mat-raised-button color="warn" class="cornered" *ngIf="expense.attachment" (click)="unattach()">
        Fjern vedlegg
      </button>
      <div class="chip cornered-left">
        <mat-chip-list>
          <mat-chip>Nr {{expense.no}}</mat-chip>
        </mat-chip-list>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout='row' fxLayout.xs='column'>
      <mat-form-field fxFlex.xs="100%" fxFlex="95px">
        <input matInput placeholder="Beløp" type="number" [(ngModel)]="expense.amount" name="amount" />
      </mat-form-field>
      <mat-form-field fxFlex.xs="100%" fxFlex>
        <input matInput placeholder="Beskrivelse" type="text" [(ngModel)]="expense.name" name="name" maxlength="100" />
        <mat-hint *ngIf="expense.name.length > 70">{{expense.name.length || 0}}/100 tegn</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex.xs="100%" fxFlex="250px">
        <input matInput placeholder="Vedlegg" type="text" disabled [(ngModel)]="expense.attachment" name="attachment" />
      </mat-form-field>
      <button mat-mini-fab color="basic" class="attachment" matTooltip="{{filePickerDescription}}"
        aria-label="Knapp åpner en filutforsker for å velge et vedlegg som kan knyttes til utlegget" ngxFilePicker
        [readMode]="readMode" (filePick)="attach($event)" *ngIf="!expense.attachment">
        <mat-icon aria-label="{{filePickerDescription}}">attach_file</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>