import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountsClient, Account } from './httpclient';
 
@Injectable()
export class AccountResolver implements Resolve<Account> {
   
  constructor(private accountsClient: AccountsClient ) { }
 
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any>|Promise<any> {  
    return this.accountsClient.readAccount(route.params.session || '');
  } 
}