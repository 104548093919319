<div class="loader" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<form #travelForm="ngForm" (keydown.enter)="$event.preventDefault()">

  <mat-toolbar color="primary" class="fixed-header">
    <mat-toolbar-row>
      <span class="fill-remaining-space"></span>
      <span class="top">
        <span *ngIf="form" class="title" fxShow.xs="false">{{form.name}}</span>
        <button *ngIf="form" (click)="submit($event.currentTarget)" mat-raised-button color="accent"
          [disabled]="!travelForm.valid">Send inn</button>
        <button (click)="goHome()" mat-raised-button>Min side</button>
      </span>
      <span class="fill-remaining-space"></span>
    </mat-toolbar-row>
  </mat-toolbar>
  <main>

    <div *ngIf="form == null && session">
      <mat-card>
        <mat-card-header>
        <mat-card-title>
          Ingen tilgang
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Sørg for at du har en gyldig sesjon. Prøv eventuelt å logg ut og deretter inn igjen. Hvis det ikke fungerer så anbefaler vi at du tar kontakt med din leverandør for å aktivere denne tjenesten for din bedrift eller organisasjon.
      </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="claim">
      <div fxLayout='row' fxLayout.sm='column' fxLayout.xs='column'>

        <div fxFlex="67%">

          <mat-card>
            <mat-card-header>
              <mat-card-title>
                Generelt
              </mat-card-title>
              <mat-card-subtitle>
                Alle utlegg må oppgis i norske kroner. Hvis bilaget gjelder reiseutgifter, må reisens formål oppgis.
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field class="full-width">
                <input matInput placeholder="Dato" [matDatepicker]="date" [(ngModel)]="claim.date" name="date" required>
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Formål" type="text" [(ngModel)]="claim.purpouse" name="purpouse" />
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Avdeling" type="text" [(ngModel)]="claim.department" name="department" />
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Utbetales til</mat-card-title>
              <mat-card-subtitle>&nbsp;</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>              
              <mat-form-field class="full-width">
                <input matInput placeholder="Navn" [(ngModel)]="claim.name" name="name" [disabled]="true" required>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Adresse" [(ngModel)]="claim.address" name="address" required>
              </mat-form-field>
              <div class="full-width">
                <mat-form-field fxFlex="33">
                  <input matInput placeholder="Postnummer" [(ngModel)]="claim.zip" name="zip" required>
                </mat-form-field>
                <mat-form-field fxFlex="67">
                  <input matInput placeholder="Sted" [(ngModel)]="claim.city" name="city" required>
                </mat-form-field>
              </div>
              <mat-form-field class="full-width">
                <input matInput required bankAccount placeholder="Kontonummer" name="bankaccount" mask="0000 00 00000"
                  [(ngModel)]="claim.bankaccount" [ngModelOptions]="{updateOn: 'blur'}" #bankaccount="ngModel">
                <mat-error *ngIf="bankaccount.invalid && (bankaccount.dirty || bankaccount.touched)">
                  Ugyldig kontonummer
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

        </div>
        <div fxFlex="33%">

          <mat-card>
            <mat-card-header>
              <mat-card-title>Utbetales av</mat-card-title>
            </mat-card-header>
            <mat-card-content>              
              <mat-card-subtitle fxHide.sm="true" fxHide.xs="true">
                &nbsp;
              </mat-card-subtitle>
              <mat-form-field class="full-width">
                <input matInput placeholder="Navn" disabled value="{{claim.client.name}}">
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput placeholder="Adresse" disabled value="{{claim.client.address}}">
              </mat-form-field>
              <div class="full-width">
                <mat-form-field fxFlex="33">
                  <input matInput placeholder="Postnummer" disabled value="{{claim.client.zip}}">
                </mat-form-field>
                <mat-form-field fxFlex="67">
                  <input matInput placeholder="Sted" disabled value="{{claim.client.city}}">
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Beløp til utbetaling</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <h2 class="sum">{{claim | claimTotal | currency:'NOK':'code':'1.2-2' }}</h2>
            </mat-card-content>
          </mat-card>
        </div>

      </div>

      <div fxLayout='column'>

        <mat-card class="expenses">          
          <mat-card-header>
            <mat-card-title>
              Mine utlegg              
            </mat-card-title>            
            <mat-card-subtitle *ngIf="claim.expenses.length <= 0">
              Du har ikke lagt til noen utlegg enda.               
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="claim.expenses.length > 0">              
              Du har lagt til {{claim.expenses.length}} utlegg.
            </mat-card-subtitle>
            <button mat-raised-button color="accent" (click)="add()" tabindex="-1" class="create">Nytt utlegg</button> 
          </mat-card-header>
        </mat-card>
        <expense *ngFor="let expense of claim.expenses" [expense]="expense" (remove)="remove($event)"
          (extensionInvalid)="showMessage($event)"></expense>

      </div>
    </div>
  </main>
</form>