import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormComponent } from "./form/form.component";
import { environment } from 'src/environments/environment';
import { Form } from './httpclient';


@Component({    
    templateUrl: 'auth.dialog.html',
  })
  export class AuthDialog {
  
    
    constructor(
      public dialogRef: MatDialogRef<FormComponent>,
      @Inject(MAT_DIALOG_DATA) public form: Form) {     
      } 
      
      public redirect(mobile: boolean){
        let signicatUrl = mobile? environment.signicatUrlMobile : environment.signicatUrlBasic;
        let url = signicatUrl + encodeURI(this.form.client.clientId + "/" + this.form.url);
        //let url = signicatUrl + encodeURI(environment.signicatUrlCallback + this.form.client.clientId + "/" + this.form.url);
        this.dialogRef.close(url);
      }
  }
