    <mat-card class="signin">
        <mat-card-header>
            <mat-card-title>Min side</mat-card-title>
            <mat-card-subtitle>
                Logg inn for å vise innsendte skjema.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <button mat-raised-button (click)="redirect(true)" class="auth-option">
                <img src="assets/bankid_main_logo.svg" alt="Logg inn med bankID" class="logo"/>
            </button>
        </mat-card-content>
    </mat-card>