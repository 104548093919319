import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";

@Injectable()
export class KnifDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {
        if (value && typeof value == "string") {
            let v: string = value;
            if (value.length >= 8) {
                v = v.split('.').reduce((cur, x) => cur + x , '');
            }
            let split: string[] = value.split('.');

            if (split.length == 3) {
                if (split[2].length == 2) {
                    split[2] = '20' + split[2];
                }
                let splitInt: number[] = split.map((x) => parseInt(x));
                return new Date(splitInt[2], splitInt[1] - 1, splitInt[0]);
            }

            if (v.length == 6) {
                return new Date(parseInt('20' + v.substr(4, 2)), parseInt(v.substr(2, 2)) - 1, parseInt(v.substr(0, 2)));
            }
            if (v.length == 8) {
                return new Date(parseInt(v.substr(4, 4)), parseInt(v.substr(2, 2)) - 1, parseInt(v.substr(0, 2)));
            }
        }
        return super.parse(value);
    }

    format(date: Date, displayFormat: Object): string
    {
        let x: string = super.format(date, displayFormat);
        let split: string[] = x.split('.');

        if (split.length == 3) {
            if (split[0].length == 1) {
                split[0] = '0' + split[0];
            }
            if (split[1].length == 1) {
                split[1] = '0' + split[1];
            }
            
            return split[0] + '.' + split[1] + '.' + split[2];
        }
        return x;
    }
    
}