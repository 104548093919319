import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormsClient, Form } from '../httpclient';


@Injectable()
export class FormResolver implements Resolve<Form> {

  constructor(private formsClient: FormsClient ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Form>|Promise<Form> {
    return this.formsClient
      .allClientFormsWithSession(+route.params.client, route.params.session || '')
      .pipe(map(forms => this.mapForms(forms, route)));
  }

  mapForms(forms: Form[], route: ActivatedRouteSnapshot): Form {
    const form = forms.filter(f => f.url === route.url[1].path);
    if (form.length > 0){
      return form[0];
    }
    return undefined;
  }
}
