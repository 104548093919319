import { Component, OnInit, ErrorHandler } from '@angular/core';
import { Expense } from './expense';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthDialog } from '../auth.dialog';
import { of } from 'rxjs';
import { FormsClient, Form } from '../httpclient';
import { Claim } from './claim';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit {

  private MAX_EXPENSE_LIMIT = 10;

  public loading: boolean;
  public form: Form;
  public claim: Claim;

  public get session(): string {
    return this.route.snapshot.params.session;
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formService: FormsClient,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler) {
  }

  openDialog(): void {
    var me = this;
    setTimeout(() => {
      const dialogRef = this.dialog.open(AuthDialog, {
        width: '350px',
        disableClose: true,
        data: this.form
      });
      dialogRef.afterClosed().subscribe(url => {
        localStorage.setItem('req_client', '' + me.form.client.clientId );
        this.router.navigate(['/externalRedirect', { externalUrl: url }]);
      });
    });
  }

  ngOnInit(): void {
    this.loading = false;
    this.route.data.subscribe(data => {
      if (data.form) {
        this.form = data.form;
        this.claim = {
          FormId: this.form.id,
          address: undefined,
          bankaccount: undefined,
          city: undefined,
          date: undefined,
          department: '',
          purpouse: '',
          zip: undefined,
          name: this.form.user.name,
          birthdate: this.form.user.birthdate,
          client: this.form.client,
          expenses: []
        };
        if (this.form.config.authenticate) {
          if (typeof this.session === 'undefined') {
            this.openDialog();
          }
        }
      } else {
        this.form = null;
        if (typeof this.session === 'undefined') {
          this.openDialog();
        }

      }
    });
  }

  hasUnsavedData(): boolean {
    return false;
  }

  get state(): string {
    return JSON.stringify(this.claim);
  }

  public add(): any {
    if (this.claim.expenses.length >= this.MAX_EXPENSE_LIMIT) {
      this.showMessage("Du kan kun legge til opptil " + this.MAX_EXPENSE_LIMIT + " utlegg per utleggsfaktura.");
    } else {
      this.claim.expenses.push({
        no: this.claim.expenses.length + 1,
        quantity: 1,
        amount: undefined,
        name: '',
        attachment: undefined,
        url: undefined,
        uploaded: () => of({ url: undefined })
      });
    }
  }

  public remove(expense: Expense) {
    this.claim.expenses = this.claim.expenses
      .filter(e => e !== expense)
      .map((e, index) => { e.no = index + 1; return e; });
  }

  public showMessage(message: string) {
    this.snackbar.open(message, null);
  }

  public redirectToAccount(id: number) {
    this.router.navigate(['account', this.session, this.form.url, id]);
  }

  public goHome() {
    this.router.navigate(['account', this.session]);
  }

  public submit(htmlElement: HTMLButtonElement): any {

    if (this.claim.expenses.length < 1) {
      this.showMessage('Minimum ett utlegg må legges til.');
      return;
    }

    if (this.claim.expenses.reduce((sum: number, expense: Expense) => sum + (expense.amount || 0), 0) === 0) {
      this.showMessage('Beløp til utbetaling kan ikke være kr 0.');
      return;
    }

    htmlElement.disabled = true;
    const cleanUp = (message: string) => {
      this.showMessage(message);
      htmlElement.disabled = false;
      this.loading = false;
    };
    this.loading = true;
    Promise.all(this.claim.expenses.map((expense) => expense.uploaded().toPromise())).then((files) => {
      const failures: Expense[] = [];
      for (let i = 0; i < files.length; i++) {
        if (!files[i].url || files[i].url === '') {
          failures.push(this.claim.expenses[i]);
        } else {
          this.claim.expenses[i].uploaded = () => of({ url: files[i].url });
          this.claim.expenses[i].url = files[i].url;
        }
      }
      if (failures.length > 0) {
        cleanUp(failures.length + ' fil(er) kunne ikke lastes opp. Vennligst prøv igjen.');
      } else {
        this.formService.add(this.claim.client.clientId, this.session, this.form.id, JSON.stringify(this.claim)).subscribe(res => {
          if (res.success) {
            this.redirectToAccount(res.id);
          } else {
            var err = res.errors[0]; // Show 'first' to handle individual errors in sequence
            if (err.indexOf("'name'") >= 0) {
              cleanUp('Beskrivelse av utlegg må fylles ut.'); 
            } else {          
              cleanUp(err); 
            }
          }
        }, (error) => {
          throw error;
        });
      }
    }).catch((error) => {
      this.errorHandler.handleError(error);
      cleanUp('Beklager, skjemaet ble ikke sendt inn på grunn av en feil.');
    });
  }
}
