import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, Injectable, Inject, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, InjectionToken } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, IConfig } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { FormComponent } from './form/form.component';
import { ExpenseComponent } from './form/expense/expense.component';
import { FormResolver } from './form/form.resolver';
import { ReadModePipe } from './read-mode.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AuthDialog } from './auth.dialog';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import { ReceiptComponent } from './receipt/receipt.component';
import { ExternalUrlDirective } from './external-url.directive';
import * as Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { BankAccountValidator } from './form/validator/bankaccount-validator';
import { KnifDateAdapter } from './knif.dateadapter';
import { UnsavedGuard } from './unsaved.guard';
import { AccountComponent } from './account/account.component';
import { FormsClient, API_BASE_URL, AccountsClient } from './httpclient';
import { AccountResolver } from './account.resolver';
import { ClaimTotalPipe } from './form/claim-total.pipe';
import { AuthInterceptor } from './auth.interceptor';
import { ClientsDialog } from './account/clients.dialog';

registerLocaleData(localeNb, 'nb');

const rollbarConfig = {
  accessToken: environment.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'knif-forms'
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {
    console.error(err);
    this.rollbar.error(err.originalError || err);
  }
}

const maskConfig: Partial<IConfig> = {
  clearIfNotMatch: false,
  showMaskTyped: false,
  validation: true
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const appRoutes: Routes = [
  { path: 'account/:session/utlegg/:claimId', component: AccountComponent, resolve: { account: AccountResolver } },
  { path: 'account/:session', component: AccountComponent, resolve: { account: AccountResolver } },
  { path: ':client/utlegg/:session', component: FormComponent, resolve: { form: FormResolver }, canDeactivate: [UnsavedGuard] },
  { path: ':client/utlegg', component: FormComponent, resolve: { form: FormResolver }, canDeactivate: [UnsavedGuard] },
  { path: 'login', component: HomeComponent },
  { path: 'ferdig', component: ReceiptComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    component: HomeComponent,
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        AccountComponent,
        FormComponent,
        ExpenseComponent,
        AuthDialog,
        ClientsDialog,
        ReadModePipe,
        ReceiptComponent,
        ExternalUrlDirective,
        BankAccountValidator,
        ClaimTotalPipe
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false }),
        NgxMaskDirective, 
        NgxMaskPipe,        
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatCardModule,
        MatToolbarModule,
        MatSelectModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        NgxFileHelpersModule,
        FlexLayoutModule,
        MatChipsModule,
        MatExpansionModule
    ],
    providers: [
        FormsClient,
        AccountsClient,
        UnsavedGuard,
        FormResolver,
        AccountResolver,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: API_BASE_URL,
            useValue: environment.serverUrl
        },
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                window.open(externalUrl, '_self');
            }
        },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        { provide: LOCALE_ID, useValue: 'nb' },
        { provide: MAT_DATE_LOCALE, useValue: 'nb' },
        { provide: DateAdapter, useClass: KnifDateAdapter },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                duration: 5000,
                horizontalPosition: "center",
                verticalPosition: "top",
            }
        },
        provideEnvironmentNgxMask(maskConfig)
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
