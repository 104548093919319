import { Component, OnInit, ErrorHandler } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, FormSubmit, AccountsClient, FormsClient, Form, Client } from '../httpclient';
import { ClientsDialog } from './clients.dialog';

@Component({
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  constructor(private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private accountsClient: AccountsClient,
    private formsClient: FormsClient,
    private snackbar: MatSnackBar,
    private errorHandler: ErrorHandler,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl('assets/thumbs-up.svg'));
  }

  pageTitle = 'Mine utlegg';
  preview: FormSubmit;
  account: Account;
  session: string;
  forms: FormSubmit[] = [];

  get clients(): number[] {
    return this.forms.reduce((list, curr) => {
      if (list.indexOf(curr.client) === -1) {
        list.push(curr.client);
      }
      return list;
    }, []);
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.account = data.account;
      this.session = this.route.snapshot.params.session;
      const id = this.route.snapshot.params.claimId;
      this.forms = this.account.forms;
      if (this.forms.length > 0 && id) {
        const form = this.account.forms.filter(f => f.id === id)[0];
        if (form) {
          this.onSelect(form);
        }
      } else {
          var client = localStorage.getItem('req_client');
          if (client){
            localStorage.removeItem('req_client');
            this.router.navigate([client, 'utlegg', this.session]);
          }
      }
    });
  }

  onSelect(item: FormSubmit): void {
    this.preview = item;
  }

  onBack(): void {
    this.preview = null;
  }

  logout(): void {
    this.accountsClient.logout(this.session).subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  createClientExpense(client: number) {
    if (client) {
      this.router.navigate([client, 'utlegg', this.session]);
    }
  }

  createExpense() {
    if (this.clients.length > 0) {
      Promise.all(this.clients.map(client => this.formsClient.allClientForms(client).toPromise())).then((clientForms) => {
        const clients: Client[] = clientForms.filter(forms => forms.length > 0).map((form) => form[0].client);
        const dialogRef = this.dialog.open(ClientsDialog, {
          data: clients
        });
        dialogRef.afterClosed().subscribe((client: Client) => {
          if (client) {
            this.createClientExpense(client.clientId);
          }
        });
      }).catch((error) => {
        this.errorHandler.handleError(error);
        this.snackbar.open('Beklager, det skjedde en feil.', null);
      });
    } else {
      this.createClientExpense(this.clients[0]);
    }
  }
}
