import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { RedwoodFile } from './redwood-file';

@Injectable({
    providedIn: 'root',
})
export class RedwoodService {

    constructor(private httpClient: HttpClient) {
    }

    upload(name: string, file: File): Observable<RedwoodFile> {
        const formData = new FormData();
        formData.append('secret', 'secret');
        formData.append('file', file, name);
        return this.httpClient.post<RedwoodFile>(environment.redwoodUrl, formData);
    }
}
